import $http from './index'

export function Baoxiu(){
    return $http.get('/Audits/SearchAuditsGson?way=1')
}

export function postActive(id){
    return $http.post('/Audits/UpdateAuditsStatus',{
        id:id
    })
}

export function postRemove(id){
    return $http.post('/Audits/RemoveAudits',{
        id:id
    })
}